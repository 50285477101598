export const PROVIDER_REFERA_DETAILS_FIELDS = {
  PROVIDES_REFERA: { name: 'providesRefera', type: 'boolean' },
  ONBOARDING_STAGE: { name: 'onboardingStage', type: 'select' },
  BLOCK_FINANCE_TRANSFERS: { name: 'blockFinanceTransfers', type: 'boolean' },

  CONTRACT_TYPE: { name: 'contractType', type: 'select' },
  CONTRACT_STATUS: { name: 'contractStatus', type: 'select' },
  CONDOLIVRE_STATUS: { name: 'condolivreStatus', type: 'select' },

  GENERATE_CONTRACT_DATE: { name: 'generateContractDate', type: 'date' },
  BLOCKED_CONTRACT_DATE: { name: 'blockedContractDate', type: 'date' },

  WHATSAPP_CONNECTION: { name: 'whatsappConnectionId', type: 'select' },
}

export const CONTRACT_TYPE_OPTIONS = [
  { value: 'standard', label: 'Padrão' },
  { value: 'lead', label: 'Lead' },
  { value: 'mixed', label: 'Misto' },
]

export const CONDOLIVRE_STATUS_OPTIONS = [
  { value: 'approved', label: 'Aprovada' },
  { value: 'processing', label: 'Processando' },
  { value: 'reproved', label: 'Reprovada' },
]

export function transformValue(value, key) {
  if (PROVIDER_REFERA_DETAILS_FIELDS[key].type === 'integer') {
    if (!value) return null

    return typeof value === 'number' ? value.toString() : value
  }

  if (PROVIDER_REFERA_DETAILS_FIELDS[key].type === 'number') {
    if (!value) return null

    const returnValue = typeof value === 'number' ? value : parseFloat(value)

    return isNaN(returnValue)
      ? null
      : returnValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  if (PROVIDER_REFERA_DETAILS_FIELDS[key].type === 'boolean') return value === true

  if (PROVIDER_REFERA_DETAILS_FIELDS[key].type === 'date') return value ?? null

  return value
}

export function castToStringifiedNumber(value) {
  let transformedValue = value
  if (typeof transformedValue === 'number') {
    transformedValue = transformedValue.toString()
  }
  const returnValue = transformedValue.replace(/[^\d,]/g, '').replace(',', '.')

  return returnValue
}
