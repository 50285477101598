/* eslint-disable react/jsx-boolean-value */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Select, MenuItem, InputLabel, FormControl, FormControlLabel } from '@material-ui/core'
import { Loader } from '@refera/ui-web'

import Accordion from '_/components/accordion'

import { InputField, InputFieldMask, PhoneInputField } from '../textfield'
import useStyles from '../styles'
import { useDispatch, useSelector } from 'react-redux'
import { financeOptionsSelector } from '_/modules/finance/selectors'
import { providerSelector } from '_modules/provider/selectors'
import useIsGenericParamActive from '_hooks/use-is-generic-param-active'
import { GENERIC_PARAMETERS } from '_utils/constants/service-order'

import SwitchComponent from '_/components/switch'
import { CPF_CNPJ_REGEX } from '_/utils/constants'
import { ControlledCpfCnpjInput } from '_/components/inputs/MaskInput/common/CpfCnpjInput'
import { validateProviderCpfCnpj } from '_/modules/provider/actions'
import { useToast } from '_/hooks/use-toast'

export const CompanyAside = ({ register, isEditing, control }) => {
  return (
    <>
      <InputField
        label="Nome da empresa"
        name="name"
        inputRef={register}
        disabled={!isEditing}
        placeholder="Digite o nome da empresa"
        required
      />
      <InputField
        label="Nome do responsável"
        name="person_responsible_name"
        disabled={!isEditing}
        placeholder="Nome completo do responsável"
        inputRef={register}
        required
      />
      <PhoneInputField
        label="Telefone"
        name="phone"
        control={control}
        placeholder="(00) 0 0000-0000"
        isEditing={isEditing}
        required
      />
      <InputField
        label="Email"
        type="email"
        name="contact_email"
        inputRef={register}
        disabled={!isEditing}
        placeholder="Digite o e-mail"
        required
      />
    </>
  )
}

export const CompanyPrincipalData = ({ isEditing, data, setValue }) => {
  const styles = useStyles()
  const isEmergencyManagementActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.EMERGENCY_MANAGEMENT,
  })

  return (
    <Accordion title="Dados principais" className={styles.accordion}>
      {isEmergencyManagementActive && (
        <FormControl>
          <InputLabel variant="standard" id="priority_for_emergencies" shrink>
            Prioridade para emergências
          </InputLabel>
          <FormControlLabel
            className={styles.switch}
            control={
              <SwitchComponent
                label="Prioridade para emergências"
                checked={!!data?.priority_for_emergencies}
                onChange={e => setValue('priority_for_emergencies', e.target.checked)}
                disabled={!isEditing}
              />
            }
          />
        </FormControl>
      )}
    </Accordion>
  )
}

const CPF_REGEX = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/

export const CompanyData = ({ isEditing, control, data }) => {
  const styles = useStyles()
  const taxRegimeOptions = useSelector(financeOptionsSelector)?.taxRegimes
  const { watch, errors } = useFormContext()
  const provider = useSelector(providerSelector)
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const [loading, setLoading] = useState(false)
  const [cpfCnpjToValidate, setCpfCnpjToValidate] = useState('')
  const menuProps = useMemo(
    () => ({
      classes: { paper: styles.subjectField },
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
    []
  )

  const handleCpfCnpjBlur = useCallback(() => {
    if (errors?.cnpj) return

    const value = watch('cnpj')
    const onlyNumbers = value.replace(/\D/g, '') || ''

    if (onlyNumbers === provider?.cnpj || value === cpfCnpjToValidate) return

    setCpfCnpjToValidate(value)
  }, [errors?.cnpj, cpfCnpjToValidate, provider?.cnpj])

  useEffect(() => {
    if (cpfCnpjToValidate) {
      const actionType = provider?.id ? 'update' : 'create'

      setLoading(true)

      dispatch(validateProviderCpfCnpj({ type: actionType, cpf_or_cnpj: watch('cnpj') }))
        .then(response => {
          if (response?.message)
            showToast({
              type: 'info',
              message: response?.message,
            })
          setLoading(false)
        })
        .catch(error => {
          showToast({
            type: 'error',
            message: error?.error_message || 'Erro ao validar CPF/CNPJ',
          })
          setLoading(false)
        })
    }
  }, [cpfCnpjToValidate, provider?.id])

  const isValidCpf = useMemo(() => {
    return CPF_REGEX.test(watch('cnpj'))
  }, [watch('cnpj')])

  return (
    <Accordion title="Dados da empresa" className={styles.accordion}>
      <Loader hasBackdrop open={loading} label="Aguarde..." />
      <ControlledCpfCnpjInput
        name="cnpj"
        label="CPF/CNPJ"
        showErrorMessage
        errorMessage={errors?.cnpj?.message}
        className={styles.cpfCnpjField}
        controllerProps={{
          rules: {
            pattern: {
              value: CPF_CNPJ_REGEX,
              message: 'CPF/CNPJ inválido',
            },
          },
        }}
        fieldProps={{
          error: !!errors?.cnpj,
          disabled: !isEditing,
          onBlur: handleCpfCnpjBlur,
        }}
        required
        defaultValue={provider?.cnpj}
      />
      <FormControl>
        <InputLabel variant="standard" id="tax_regime" shrink>
          Regime tributário
        </InputLabel>
        <Controller
          control={control}
          name="tax_regime"
          defaultValue={data?.taxRegime || ''}
          as={
            <Select
              MenuProps={menuProps}
              labelId="tax-regime"
              disabled={!isEditing || isValidCpf}
              required
            >
              {taxRegimeOptions?.map(object => {
                return (
                  <MenuItem key={object.label} value={object.value}>
                    {object.label}
                  </MenuItem>
                )
              })}
            </Select>
          }
        />
      </FormControl>
      <InputFieldMask
        label="Data do cadastro"
        name="company_creation_date"
        control={control}
        mask="99/99/9999"
        placeholder="DD/MM/AAAA"
        isEditing={isEditing}
      />
      <InputFieldMask
        label="Número de funcionários"
        name="number_of_employees"
        control={control}
        mask="9999"
        placeholder="0000"
        isEditing={isEditing}
      />
    </Accordion>
  )
}

export const CompanyAddress = ({ register, isEditing, control }) => {
  const styles = useStyles()

  return (
    <Accordion title="Endereço da empresa" className={styles.accordion}>
      <InputField
        label="Endereço"
        name="address"
        inputRef={register}
        disabled={!isEditing}
        placeholder="Digite o endereço"
      />
      <InputField
        label="Número"
        name="street_number"
        inputRef={register}
        disabled={!isEditing}
        placeholder="0000"
      />
      <InputFieldMask
        label="CEP"
        name="cep"
        control={control}
        mask="99999-999"
        placeholder="00000-000"
        isEditing={isEditing}
      />
      <InputField
        label="Complemento"
        name="extra_address_info"
        inputRef={register}
        disabled={!isEditing}
        placeholder="Digite o complemento"
      />
      <InputField
        label="Bairro"
        name="neighborhood"
        inputRef={register}
        disabled={!isEditing}
        placeholder="Digite o bairro"
      />
      <InputField
        label="Cidade"
        name="city"
        inputRef={register}
        disabled={!isEditing}
        placeholder="Digite a cidade"
      />
      <InputField
        label="Ponto de referência"
        name="nearby_landmark"
        inputRef={register}
        disabled={!isEditing}
        placeholder="Digite uma referência"
      />
      <InputField
        label="Estado"
        name="state"
        inputRef={register}
        disabled={!isEditing}
        placeholder="Digite o estado"
      />
    </Accordion>
  )
}
