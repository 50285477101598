/* eslint-disable eqeqeq */
/* eslint-disable no-useless-return */
/* eslint-disable no-else-return */
import React, { useEffect, useCallback, useState } from 'react'
import axios from 'axios'
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useParams, navigate, useLocation } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import { categoriesSelector } from '_modules/categories/selectors'
import { getToken, camelize } from '_/utils/token'
import { API_URL } from '_config/environment'
import useBoolean from '_hooks/use-toggle'
import { banksLoadingSelector } from '_modules/utils/selectors'
import Button from '_components/button'
import { getCategories } from '_modules/categories/actions'
import AddRegions from '_/components/accordion/add-regions'
import AddCategories from '_/components/accordion/add-provider/add-categories'
import AddSpecialSkills from '_/components/accordion/add-provider/add-special-skills'
import Svg, { ICON } from '_components/svg'
import IconButton from '_components/svg/icon-button'
import { providerSelector } from '_modules/provider/selectors'

import {
  getProvider,
  GET_PROVIDER,
  deleteProviderContract,
  createProviderContract,
} from '_modules/provider/actions'
import useFetchCall from '_hooks/use-fetch-call'
import { ROUTES } from '_utils/constants'
import Loading from '_components/loading'
import RatingModal, { WARNING_MODAL } from '_components/modal/rating-modal'

import useStyles from './styles'
import {
  CompanyAside,
  CompanyData,
  CompanyAddress,
  CompanyPrincipalData,
} from './company-accordions'
import CompanyBankData from './company-accordions/company-bank-data'
import { getFinanceOptions } from '_/modules/finance/actions'
import { financeOptionsSelector } from '_/modules/finance/selectors'
import AddBlockedAgency from '_/components/accordion/add-blocked-Agency'
import AddAttachments from './provider-contracts'
import useRolePermission from '_hooks/use-role-permission'
import { userSelector } from '_/modules/authentication/selectors'

import { Alert, Toast } from '@refera/ui-web'
import { getGenericParameters } from '_modules/service-orders/actions'
import { useDialog } from '_/hooks/use-dialog'
import { validationCNPJ, validationCPF } from '_/utils/helpers'
import { cpfCnpjMask } from '../finance/payer-data/utils'
import { ProviderReferaDetailsModal } from './components/ProviderReferaDetailsModal'

const Provider = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { providerId } = useParams()

  const [isEditing, handleEdit] = useBoolean(!providerId)
  const [isGettingProvider] = useFetchCall(GET_PROVIDER.ACTION)
  const [bank, setBank] = useState()
  const [loading, setLoading] = useState(false)
  const taxRegimeOptions = useSelector(financeOptionsSelector)?.taxRegimes
  const [ratingModal, setRatingModal] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  })
  const [isReferaDetailsModalOpen, setIsReferaDetailsModalOpen] = useState(false)
  const user = useSelector(userSelector)
  const provider = useSelector(providerSelector)
  const services = useSelector(categoriesSelector)
  const isBanksLoading = useSelector(banksLoadingSelector)
  const isAdmin = useRolePermission()

  const methods = useForm({
    mode: 'all',
  })
  const { register, control, watch, setValue, handleSubmit, getValues, setError } = methods
  const formData = watch()

  const [attachments, setAttachments] = useState([])
  const [toDelete, setToDelete] = useState([])
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' })
  const { showDialog, closeDialog } = useDialog()
  const { state } = useLocation()

  const handleDefault = () => {
    register('cities')
    setValue('cities', provider?.cities?.toJS() || [])

    register('main_services')
    setValue('main_services', provider?.mainServices.toJS() || [])

    register('priority_for_emergencies')
    setValue('priority_for_emergencies', provider?.priorityForEmergencies || false)

    register('get_contract_options')
    setValue('get_contract_options', provider?.getContractOptions?.toJS() || [])

    register('main_blocked_agencies')
    setValue(
      'main_blocked_agencies',
      provider?.mainBlockedAgencies.toJS().map((blockedAgenciesId, index) => {
        return {
          id: blockedAgenciesId,
          name: provider?.mainBlockedAgenciesNames.toJS()[index],
        }
      }) || []
    )

    register('main_special_skills')
    setValue('main_special_skills', provider?.mainSpecialSkills.toJS() || [])

    setValue('name', provider?.name)
    setValue('person_responsible_name', provider?.personResponsibleName)
    setValue('phone', provider?.phone)
    setValue('contact_email', provider?.contactEmail)

    register('tax_regime')
    setValue('tax_regime', provider?.taxRegime || '')
    setValue('company_creation_date', provider?.companyCreationDateBr || '')
    setValue('number_of_employees', provider?.numberOfEmployees || '')
    setValue('cnpj', provider?.cnpj ? cpfCnpjMask(provider?.cnpj) : '')

    setValue('address', provider?.address || '')
    setValue('street_number', provider?.streetNumber || '')
    setValue('cep', provider?.cep || '')
    setValue('extra_address_info', provider?.extraAddressInfo || '')
    setValue('neighborhood', provider?.neighborhood || '')
    setValue('city', provider?.city || '')
    setValue('nearby_landmark', provider?.nearbyLandmark || '')
    setValue('state', provider?.state || '')

    setValue('bank', provider?.bank?.toJS().id || '')
    setValue('bank_account_owner_name', provider?.bankAccountOwnerName || '')
    setValue('bank_account_cpf_cnpj', provider?.bankAccountCpfCnpj || '')
    setValue('bank_account_number', provider?.bankAccountNumber || '')
    setValue('bank_account_digit', provider?.bankAccountDigit || '')
    setValue('bank_agency_number', provider?.bankAgencyNumber || '')
    setValue('bank_account_type', provider?.bankAccountType || '')
    setValue('pix_key', provider?.pixKey || '')
    setValue('pix_key_choice', provider?.pixKeyChoice || '')

    setValue('contract_attachments', provider?.contract_attachments?.toJS() || '')
  }

  const verifyRequiredField = useCallback(() => {
    setRatingModal({
      isOpen: true,
      title: 'Atenção!',
      subTitle: 'Um ou mais dados do formulário possuem problemas.',
      type: WARNING_MODAL,
    })
  }, [setRatingModal])

  const handleSuccess = useCallback(() => {
    setToast({
      isOpen: true,
      message: 'Registro salvo com sucesso.',
      type: 'success',
    })
  }, [setToast])

  const handleError = useCallback((message = 'Ocorreu um erro ao salvar o registro.') => {
    setToast({
      isOpen: true,
      message,
      type: 'error',
    })
  }, [])

  const handleCloseToast = useCallback(() => {
    setToast(prevState => ({ ...prevState, isOpen: false }))
  }, [])

  const validRequiredFields = useCallback(data => {
    if (!data?.tax_regime) {
      return false
    }
    return true
  }, [])

  const handleAttachmentsChange = useCallback(newAttachments => {
    setAttachments(newAttachments?.attachments)
    setToDelete(newAttachments?.toDelete)
  }, [])

  const handleSave = async savedata => {
    const data = savedata

    if (data?.cnpj) {
      const isValid =
        data?.cnpj.replace(/[^0-9]/g, '').length === 14
          ? validationCNPJ(data?.cnpj)
          : validationCPF(data?.cnpj)
      if (!isValid) {
        setError('cnpj', {
          type: 'manual',
          message: 'CPF/CNPJ inválido.',
        })
        return
      }
    }

    const newAttachments = attachments.filter(item => item?.id === undefined)

    if (data?.street_number === '') {
      delete data.street_number
    }
    if (data?.company_creation_date === '') {
      delete data.company_creation_date
    }
    if (data?.phone) {
      data.phone = data.phone.replace(/[^A-Z0-9]/gi, '')
    }
    if (!validRequiredFields(data)) {
      verifyRequiredField()
      return
    } else {
      const headers = getToken()
      try {
        setLoading(true)
        const editData = {
          ...data,
          main_blocked_agencies: data.main_blocked_agencies.map(item => item.id),
        }

        delete editData.generate_contract_date

        if (providerId) {
          // dispatch(updateProvider(editData))
          const response = await axios.patch(`${API_URL}/company/${providerId}/`, editData, headers)
          if (response.status == 200) {
            handleSuccess()
          }

          if (toDelete.length > 0) {
            toDelete.map(fileID => dispatch(deleteProviderContract(fileID)))
            setToDelete([])
          }

          if (newAttachments && newAttachments.length !== provider?.contract_attachments?.toJS()) {
            try {
              await dispatch(createProviderContract({ contractAttachments: newAttachments }))
            } catch (error) {
              handleError(
                'O registro foi salvo com sucesso. Mas ocorreu um erro ao anexar arquivos de contrato.'
              )
            } finally {
              setLoading(false)
            }
          }
          handleEdit()
        } else {
          // CREATE REQUEST
          // const companyInfo = dispatch(createProvider(editData))
          const companyInfo = await axios.post(`${API_URL}/company/`, editData, headers)
          if (companyInfo.status == 200) {
            handleSuccess()
          }
          handleEdit()
          if (companyInfo?.data?.redirect_to)
            navigate(`${ROUTES.PROVIDER}/${companyInfo.data.redirect_to}`, {
              state: { redirectedToAlreadyExistingProvider: true },
            })
          else navigate(`${ROUTES.PROVIDER}/${companyInfo.data.id}`)
        }
      } catch (error) {
        verifyRequiredField()
        handleError(error?.response?.data?.error_message)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleNotSave = () => {
    if (providerId) {
      handleDefault()
      handleEdit()
      return
    }
    navigate(ROUTES.MANAGE_PROVIDERS)
  }

  const handleBackNavigation = useCallback(() => {
    if (providerId) {
      navigate(ROUTES.MANAGE_PROVIDERS)
      return
    }
    navigate(-1)
  }, [providerId])

  const handleGetFinanceOptions = useCallback(() => {
    if (taxRegimeOptions?.length === 0) {
      dispatch(getFinanceOptions())
    }
  }, [dispatch, taxRegimeOptions])
  // --------- "GET" REQUESTS FOR SELECT OPTIONS
  useEffect(() => {
    const headers = getToken()

    axios.get(`${API_URL}/bank/`, headers).then(res => setBank(camelize(res.data.results)))
  }, [])

  useEffect(() => {
    if (!services?.length) {
      dispatch(getCategories())
    }
  }, [dispatch, services?.length])

  useEffect(() => {
    if (providerId) {
      dispatch(getProvider(providerId))
    }
  }, [dispatch, providerId])

  useEffect(() => {
    if (state?.redirectedToAlreadyExistingProvider) {
      showDialog({
        type: 'info',
        subject:
          'Este prestador já foi cadastrado por uma imobiliária, e agora faz parte do time da Refera.',
        labelApprove: 'Ok',
        onApprove: closeDialog,
      })
    }
    handleGetFinanceOptions()
    dispatch(
      getGenericParameters({
        name: 'emergency_management',
      })
    )
  }, [])

  useEffect(() => {
    handleDefault()
  }, [provider, handleEdit])
  // --------------------------------------------

  if (isGettingProvider && isBanksLoading) {
    return <Loading />
  }

  const serviceToAddSpacialSkills = getValues('main_services')

  const isCsRefera = user?.getRoles?.includes('cs_refera')

  const onEditButtonClick = () => {
    if (provider?.messageNotAllowedToUpdate) {
      showDialog({
        type: 'warning',
        title: 'Atenção!',
        subject: provider?.messageNotAllowedToUpdate,
        labelApprove: 'Ok',
        onApprove: closeDialog,
      })
      return
    }

    handleEdit()
  }

  return (
    <Grid className={styles.main}>
      <Grid container justify="center" alignItems="center" className={styles.save}>
        <IconButton
          buttonClassName={styles.navigate}
          aria-label="Voltar página"
          onClick={handleBackNavigation}
        >
          <ArrowBackIcon />
        </IconButton>
        <Grid className={styles.buttonWrapper}>
          {!isEditing ? (
            <div className={styles.headerButtonsContainer}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setIsReferaDetailsModalOpen(true)}
                disabled={isGettingProvider || isBanksLoading}
                style={{ padding: '10px 16px' }}
              >
                Dados da Refera
              </Button>
              <Button onClick={onEditButtonClick} className={styles.buttonEdit} variant="outlined">
                Editar informações
                <Svg className={styles.buttonEditIcon} type={ICON.EDIT} />
              </Button>
            </div>
          ) : (
            <>
              <Typography variant="subtitle1" color="primary">
                Deseja salvar?
              </Typography>
              <Button
                className={styles.button}
                startIcon={loading ? <></> : <CheckIcon />}
                isLoading={loading}
                color="primary"
                variant="contained"
                type="submit"
                form="provider-form"
              >
                Sim
              </Button>
              <Button
                onClick={handleNotSave}
                startIcon={<CloseIcon />}
                color="primary"
                variant="outlined"
              >
                Não
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <FormProvider {...methods}>
        <form className={styles.main} id="provider-form" onSubmit={handleSubmit(handleSave)}>
          <Grid className={styles.container}>
            <Grid className={styles.aside}>
              <CompanyAside
                register={register}
                isEditing={isEditing}
                control={control}
                provider={provider}
                isCsRefera={isCsRefera}
              />
            </Grid>
            <Grid className={styles.accordionSection}>
              <CompanyPrincipalData
                isEditing={isEditing}
                control={control}
                setValue={setValue}
                data={formData}
              />
              <CompanyData isEditing={isEditing} control={control} data={formData} />
              <CompanyAddress register={register} isEditing={isEditing} control={control} />
              <AddRegions
                isProvider
                isEditing={isEditing}
                staffUsers={provider?.staffUsers?.valueSeq()?.toArray()}
                setValue={setValue}
              />
              <AddCategories
                isProvider
                setValue={newValue => {
                  setValue('main_services', newValue)
                }}
                control={control}
                isEditing={isEditing}
                data={formData?.main_services}
              />
              <AddSpecialSkills
                isProvider
                setValue={newValue => {
                  setValue('main_special_skills', newValue)
                }}
                control={control}
                isEditing={isEditing}
                data={formData?.main_special_skills}
                services={serviceToAddSpacialSkills}
              />
              <CompanyBankData
                register={register}
                isEditing={isEditing}
                control={control}
                provider={provider}
                bank={bank}
              />
              <AddBlockedAgency
                isProvider
                setValue={setValue}
                control={control}
                isEditing={isEditing}
                data={formData?.main_blocked_agencies}
                name="main_blocked_agencies"
              />

              <AddAttachments
                provider={provider}
                isEditing={isAdmin ? isEditing : !isEditing}
                control={control}
                onChange={handleAttachmentsChange}
              />
            </Grid>
            <RatingModal ratingModal={ratingModal} setRatingModal={setRatingModal} />
          </Grid>
          {isReferaDetailsModalOpen && (
            <ProviderReferaDetailsModal
              open={isReferaDetailsModalOpen}
              onClose={() => setIsReferaDetailsModalOpen(false)}
            />
          )}
        </form>
      </FormProvider>
      {toast.isOpen && (
        <Toast draggable open={toast.isOpen} autoHideDuration={6000} onClose={handleCloseToast}>
          <Alert severity={toast.type} title={toast.message} onClose={handleCloseToast} />
        </Toast>
      )}
    </Grid>
  )
}
export default Provider
