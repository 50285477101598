import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  form: {
    width: '100%',
    paddingTop: '2.5rem',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto',
    gap: '1rem',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '2rem',
  },
  labelNumberInput: {
    marginTop: '-37px !important',
  },
  modalContent: {
    paddingBottom: '1.5rem',
  },
  switchInputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-13px',
    gap: '1px',
  },
  switchLabel: {
    color: palette.gray[64],
    marginBottom: spacing(6),
    fontSize: spacing(12),
    width: 'max-content',
    display: 'block',
  },
  dateTimePicker: {
    flex: 1,

    '& label': {
      color: `${palette.gray[64]} !important`,
      fontFamily: 'Roboto !important',
      fontWeight: 'normal !important',
    },

    '& .MuiInput-underline': {
      marginTop: '12px !important',
    },

    '& input:disabled': {
      cursor: 'not-allowed !important',
    },
  },
  datePickerInputGroup: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,

    '& input::placeholder': {
      color: 'transparent !important',
    },

    '& input:disabled': {
      cursor: 'not-allowed !important',
    },

    '& input:disabled::placeholder': {
      color: 'transparent !important',
      opacity: 0,
    },
  },
  datePickerLabel: {
    color: palette.gray[64],
    fontFamily: 'Roboto',
    fontSize: '16px !important',
  },
  checkboxContainer: {
    justifyContent: 'flex-start',
    marginTop: '-8px !important',
    width: '240px',
  },
}))
